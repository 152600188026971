import React from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = { records: [] };
  }

  componentDidMount() {
    fetch(
      `https://api.airtable.com/v0/${process.env.REACT_APP_AIRTABLE_BASE_KEY}/Contacts?view=${this.props.view}&maxRecords=${this.props.maxRecords}`,
      {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_API_KEY}`
        })
      }
    )
      .then((response) => response.json())
      .then((data) => this.setState({ records: data.records }));
  }

  renderContacts() {
    return this.state.records.map((record) => {
      return <Box 
        key={record.id.toString()} 
        sx={{ 
          height: 'auto', 
          width: '100%',
          backgroundColor: '#111',
          display: 'flex',
          alignItems: 'center',
          padding: '10px 40px',
          border: '1px solid #333',
          '&:hover': {
            border: '1px solid #555'
          }, }}>
            <Stack spacing={1}>
              <Typography variant="h6">
                {record.fields.full_name}
                <Link href={'mailto:' + record.fields.email} underline="none">
                  <IconButton color="secondary" aria-label="email contact">
                    <EmailIcon />
                  </IconButton>
                </Link>
                <Link href={record.fields.person_linked_in_url} target="_blank" underline="none">
                  <IconButton color="secondary" aria-label="visit linkedin page">
                    <LinkedInIcon />
                  </IconButton>
                </Link>
              </Typography>
              <Typography>{record.fields.title} @ <Link href={record.fields.website} target="_blank">{record.fields.company}</Link></Typography>
            </Stack>
          </Box>
    });
  }

  render() {
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Box sx={{ width: '100%' }}>
          <Stack spacing={2}>
            {this.renderContacts()}
          </Stack>
        </Box>
      </ThemeProvider>
    );
  }
}

export default List;
